<template>
    <div class="error">
        <p>拒绝访问</p>
        <div @click="goIndex">返回首页</div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        goIndex(){
            window.location.href = 'https://fulu-yktv-h5.fulu.com'
        }
    }
}
</script>
<style lang="less" scoped>
    .error{
        padding-top:6rem;
        p{
            text-align: center;
            font-size:16px;
            color:#333;
        }
        div{
            text-align: center;
            margin-top:0.60rem;
            font-size:14px;
            text-decoration: underline;
            color:#1989fa;
        }
    }
</style>